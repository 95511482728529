.invoice-container {
    padding: 30px; /* Increased padding for more space */
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
    width: 80%;
    margin: auto;
    margin-top: 50px; /* Added top margin for more spacing */
  }
  
  .company-name {
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .customer-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .order-details-title {
    margin: 20px 0;
    font-weight: bold;
  }
  
  .table {
    margin-bottom: 20px;
  }
  
  .total-amount {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .gst-details {
    margin: 20px 0;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  
  .terms-title {
    margin: 20px 0;
    font-weight: bold;
  }
  
  .terms-and-conditions {
    margin: 10px 0;
    line-height: 1.5;
  }
  