.product-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .product-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #ebeced; /* Light grey */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .product-row:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .heading-row {
    background-color: #0057d1;
    color: white;
    font-weight: bold;
  }
  
  .total-row {
    background-color: #0057d1;
    color: white;
    font-weight: bold;
  }
  
  .scrollable-content {
    max-height: 30vh;
    overflow-y: auto;
  }

  .orderbtn{
    background-color: #b5d1f9;
    color: #000;
  }

  .orderbtn:hover {
    background-color: #59595a;
    color: #ffffff;
  }
  